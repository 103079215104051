import React, { FC } from 'react';
import classNames from 'classnames';

import { FieldProps } from './models';

import './Field.scss';

const Field: FC<FieldProps> = ({
  label,
  name,
  value,
  handleChange,
  error,
  placeholder,
  isRequired = false,
  fieldId,
  errorMessagePosition = 'top',
}) => (
  <div className={classNames('field', { 'field--error': error })}>
    <div className="field__top">
      <label className="field__label" htmlFor={fieldId || name}>
        {isRequired ? (
          <>
            {label}
            <span className="field__label-indicator">*</span>
          </>
        ) : (
          label
        )}
      </label>

      {errorMessagePosition === 'top' && error ? <p className="field__error">{error}</p> : null}
    </div>

    <input
      className="field__input"
      name={name}
      onChange={handleChange}
      value={value}
      id={fieldId || name}
      placeholder={placeholder}
    />

    {errorMessagePosition === 'bottom' ? (
      <div className="field__bottom">{error ? <p className="field__error">{error}</p> : null}</div>
    ) : null}
  </div>
);

export default Field;
