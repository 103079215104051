import { FORM_FIELDS } from 'components/NewsletterPopup/constants';

const validateDate = (value: string): boolean => {
  const newDate = new Date();
  const presentYear = newDate.getFullYear();
  const presentMonth = newDate.getMonth() + 1;

  const validatePattern = /^(\d{2})(-)(\d{2})(-)(\d{4})$/;
  const dateValues = value.match(validatePattern);

  if (dateValues == null) return false;

  const birthDate = parseInt(dateValues[1], 10);
  const birthMonth = parseInt(dateValues[3], 10);
  const birthYear = parseInt(dateValues[5], 10);

  return !(
    birthMonth < 1 ||
    birthMonth > 12 ||
    (birthMonth > presentMonth && birthYear === presentYear) ||
    birthDate < 1 ||
    birthDate > 31 ||
    birthYear > presentYear ||
    birthYear + 90 < presentYear
  );
};

const validateOnChange = (field, fields, errors) => {
  if (!field.value && fields[field.name].isRequired) {
    errors[field.name] = fields[field.name].errorMessage;
  } else if (field.name === FORM_FIELDS.EMAIL && !/\S+@\S+\.\S+/.test(field.value)) {
    errors.email = fields.email.additionalErrorMessage;
  } else if (field.name === FORM_FIELDS.DATE && !validateDate(field.value)) {
    errors.dateOfBirth = fields.dateOfBirth.additionalErrorMessage;
  } else {
    errors[field.name] = undefined;
  }
};

export const validate = (fields) => ({ values, field }) => {
  const errors = {} as any;

  if (field) {
    validateOnChange(field, fields, errors);

    return errors;
  }

  Object.entries(fields).forEach(([key, value]: any) => {
    if (!values[key] && value.isRequired) {
      errors[key] = value.errorMessage;
    }
  });

  if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = fields.email.additionalErrorMessage;
  }

  return errors;
};
