import { useEffect, useState } from 'react';

const FIELD_NAMES = {
  DATE_OF_BIRTH: 'dateOfBirth',
  CHECKBOX: 'checkbox',
};

const useForm = (initialValues, callback, validate) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && !isSent) {
      callback(values);
      setIsSent(true);
    }
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (validate) {
      setErrors(validate({ values }));
    }
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    event.persist();
    const { name, value, checked } = event.target;
    let localValue = value;

    const isDateField = name === FIELD_NAMES.DATE_OF_BIRTH;
    const isCheckboxField = name === FIELD_NAMES.CHECKBOX;

    setValues((prev) => {
      if (isDateField) {
        const [, d, m, y] = value
          .slice(0, 10)
          .replace(/\D/g, '')
          .match(/(\d{0,2})(\d{0,2})(\d{0,4})/);

        localValue = !m ? d : `${d}-${m}${y ? `-${y}` : ''}`;
      }

      if (isCheckboxField) {
        localValue = checked;
      }

      return {
        ...prev,
        [name]: localValue,
      };
    });

    if (validate && !isCheckboxField) {
      setErrors((prev) => ({
        ...prev,
        ...validate({ field: { name, value: localValue } }),
      }));
    }
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  };
};

export default useForm;
