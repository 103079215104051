import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { graphql, useStaticQuery } from 'gatsby';
import useForm from 'hooks/useForm';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Button, { VisualType, VisualVariant } from 'common/Button';
import Field from 'common/Field';
import { setBodyScrolling } from 'utils/browser';
import cookieUtils from 'utils/cookie';
import { gtmService } from 'utils/gtmService';
import { validate } from 'utils/newsletterFormValidation';

import { submit } from './actions';
import {
  FORM_FIELDS,
  initialValues,
  LOCK_SCROLL,
  LONG_DELAY_DEFAULT,
  NL_FORM_STATUS,
  SHORT_DELAY_DEFAULT,
  STATUS_POSTPONED,
  TIME_TO_SHOW_DEFAULT,
  UNLOCK_SCROLL,
} from './constants';
import { INewsletterPopupProps, INewsletterPopupQueryResponse } from './models';

import './NewsletterPopup.scss';

export const NewsletterPopup: FC<INewsletterPopupProps> = ({ actualLang }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);

  const fieldsParentRef = useRef<HTMLDivElement>(null);

  const {
    allSharedComponentsSettings: { nodes: sharedComponentsSettings },
    allNewsletterPopup: { nodes: newsletterPopup },
  } = useStaticQuery<INewsletterPopupQueryResponse>(graphql`
    {
      allNewsletterPopup {
        nodes {
          ...NewsletterPopupFragment
        }
      }
      allSharedComponentsSettings {
        nodes {
          lang
          newsletterSignUpData {
            brand
            deliveryStatus
            formId
            leadCustomerBrandOptIn
            marketingOptin
            pp
            rbCommercialBrandOptIn
            tierCode
            agreements {
              businessId
              consentAcceptedInd
              consentDesc
              mandatoryInd
              revisionId
            }
          }
        }
      }
    }
  `);

  const {
    title,
    subtitle,
    disclaimer,
    emailField: email,
    firstNameField: firstName,
    lastNameField: lastName,
    dateOfBirthField: dateOfBirth,
    signUpButton,
    closeButton,
    consentText,
    timeToShow,
    shortDelay,
    longDelay,
    thankYouMessage,
  } = newsletterPopup.find(({ lang }) => lang === actualLang)!;

  const { newsletterSignUpData } = sharedComponentsSettings.find(
    ({ lang }) => lang === actualLang
  )!;

  const timeToShowPopup = timeToShow ? parseInt(timeToShow, 10) * 1000 : TIME_TO_SHOW_DEFAULT;
  const shortDelayPopup = shortDelay ? parseInt(shortDelay, 10) * 3600 : SHORT_DELAY_DEFAULT;
  const longDelayPopup = longDelay ? parseInt(longDelay, 10) * 3600 : LONG_DELAY_DEFAULT;

  useEffect(() => {
    setTimeout(() => {
      setShowPopup(true);
      if (fieldsParentRef.current) fieldsParentRef.current.querySelector('input')?.focus();
      setBodyScrolling(LOCK_SCROLL);
    }, timeToShowPopup);

    return () => {
      setBodyScrolling(UNLOCK_SCROLL);
    };
  }, []);

  const handleClosePopup = useCallback(() => {
    setBodyScrolling(UNLOCK_SCROLL);
    if (!isFormSent) {
      cookieUtils.setCookie(NL_FORM_STATUS, STATUS_POSTPONED, { 'max-age': shortDelayPopup });
    }
    setShowPopup(false);
  }, [isFormSent]);

  const handlePopupBlur = useCallback((event: React.FocusEvent) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      if (fieldsParentRef.current) fieldsParentRef.current.querySelector('input')?.focus();
    }
  }, []);

  const postSubmitCallback = useCallback(() => {
    setIsFormSent(true);
    gtmService.emitGenerateLead(gtmService.formNames.newsletter);
    cookieUtils.setCookie(NL_FORM_STATUS, STATUS_POSTPONED, {
      'max-age': longDelayPopup,
    });
  }, []);

  const { values, errors, handleChange, handleSubmit } = useForm(
    initialValues,
    submit({ newsletterSignUpData, postSubmitCallback }),
    validate({ email, firstName, lastName, dateOfBirth })
  );

  const formFields = [
    { name: FORM_FIELDS.FIRST_NAME, ...firstName },
    { name: FORM_FIELDS.LAST_NAME, ...lastName },
    { name: FORM_FIELDS.EMAIL, ...email },
    { name: FORM_FIELDS.DATE, ...dateOfBirth },
  ];

  return showPopup ? (
    <div className="newsletter-popup" data-testid="newsletter-popup">
      <div className="newsletter-popup__backdrop">
        <div className="newsletter-popup__form-wrapper--outer">
          <div className="newsletter-popup__form-wrapper--inner" onBlur={handlePopupBlur}>
            <div className="newsletter-popup__header">
              <p className="newsletter-popup__title">{title}</p>
              {subtitle ? <p className="newsletter-popup__subtitle">{subtitle}</p> : null}
            </div>

            <div className="newsletter-popup__main">
              {isFormSent ? (
                <div className="newsletter-popup__message">
                  <p>{thankYouMessage.title}</p>
                  <p>{thankYouMessage.text}</p>
                </div>
              ) : (
                <>
                  <p className="newsletter-popup__disclaimer">
                    <span className="field__label-indicator">*</span>
                    {disclaimer}
                  </p>

                  <Form onSubmit={handleSubmit} className="newsletter-popup__form">
                    <div className="newsletter-popup__form-fields" ref={fieldsParentRef}>
                      {formFields.map(({ name, label, placeholder, isRequired }) => (
                        <Field
                          key={name}
                          label={label}
                          name={name}
                          fieldId={`popup-${name}`}
                          value={values[name]}
                          handleChange={handleChange}
                          error={errors[name]}
                          placeholder={placeholder}
                          isRequired={isRequired}
                          errorMessagePosition="bottom"
                        />
                      ))}
                    </div>

                    <div className="newsletter-popup__form-consent">
                      <DangerouslySetInnerHtml html={consentText} />
                    </div>

                    <Button
                      type="submit"
                      ariaLabel={signUpButton.ariaLabel}
                      visualType={signUpButton.type[0] as VisualType}
                      visualVariant={VisualVariant.Dark}
                    >
                      {signUpButton.text}
                    </Button>
                  </Form>
                </>
              )}

              <button
                type="button"
                className="newsletter-popup__close-button"
                data-testid="close-button"
                aria-label={closeButton.ariaLabel}
                onClick={handleClosePopup}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default NewsletterPopup;
