import { HANDLER_ENDPOINT } from './constants';
import { ISubmitActionArgs } from './models';

export const submit = ({ newsletterSignUpData, postSubmitCallback }: ISubmitActionArgs) => async (
  signUpValues
): Promise<void> => {
  const {
    brand,
    deliveryStatus,
    formId,
    leadCustomerBrandOptIn,
    marketingOptin,
    pp,
    rbCommercialBrandOptIn,
    tierCode,
    agreements,
  } = newsletterSignUpData;

  const date = new Date();

  const Agreements = agreements.map(
    ({ businessId, revisionId, consentAcceptedInd, consentDesc, mandatoryInd }) => ({
      BusinessId: businessId,
      RevisionId: revisionId,
      ConsentAcceptedInd: consentAcceptedInd === 'true',
      ConsentDesc: consentDesc,
      MandatoryInd: mandatoryInd === 'true',
      AgreementDate: date,
      ActivityDate: date,
    })
  );

  const body = {
    TierCode: tierCode,
    FirstName: signUpValues.firstName,
    LastName: signUpValues.lastName,
    BirthDate: `${signUpValues.dateOfBirth.split('-').reverse().join('-')}T00:00:00Z`,
    Emails: [
      {
        EmailAddress: signUpValues.email,
        DeliveryStatus: deliveryStatus,
      },
    ],
    JsonExternalData: {
      UnmappedAttributes: {
        PP: pp === 'true',
        marketingOptin: marketingOptin === 'true',
        brand,
        lead_customer_brand_opt_in: leadCustomerBrandOptIn === 'true',
        rb_commercial_brand_opt_in: rbCommercialBrandOptIn === 'true',
        formId,
        formTime: date,
        regTime: date,
      },
      Agreements,
    },
  };

  try {
    await fetch(HANDLER_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    postSubmitCallback();
  } catch (err) {
    console.error(err);
  }
};
